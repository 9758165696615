import React from 'react';
import './index.css';
import './App.css';

const App = function () {
  return (
    <div className="App">
      <header className="App-header">
        <div className="mb-6">
          <p>俺は</p>
          <p>必ず</p>
          <p className="text-2xl">最強なマジシャンに</p>
          <p>なる！！！</p>
        </div>
        <div>
          <p>Sens dubte seré el mag més fort!</p>
        </div>
      </header>
    </div>
  );
};

export default App;
